
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var contacts = localStorage.getItem('contacts');
    if (contacts) {
      contacts = JSON.parse(contacts);
    } else {
      contacts = this.$root.contacts;
    }
    return { contacts: contacts };
  },
  methods: {
    refreshContacts: function () {
      var that = this.$app;
      var contacts = localStorage.getItem('contacts');
      if (contacts) {
        contacts = JSON.parse(contacts);
      } else {
        contacts = this.$root.contacts;
      }
      this.$setState({ contacts: contacts });
    }
  },
  id: '624af86c1c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="contact">\n    <div class="navbar">\n      <div class="navbar-inner sliding">\n        <div class="title">Contact Us</div>\n      </div>\n    </div>\n    <div class="page-content">\n      <div class="list contact-list">\n        <ul>\n          ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.contacts, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            ';
          r += Template7Helpers.js_if.call(ctx_2, '(typeof(cordova) != \'undefined\') && (this.type == \'email\')', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n          <a data-tap-disabled="true" href="#" onclick="cordova.plugins.email.open({to: \'';
              r += c(ctx_3.email, ctx_3);
              r += '\'});  return false;" class="item-link item-content external" target="_system">\n            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n          <a data-tap-disabled="true" href="';
              r += c(ctx_3.link, ctx_3);
              r += '" class="item-link item-content external" target="_system">\n            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n        <div class="item-inner">\n          <div class="item-title">\n            <div class="item-header">';
          r += c(data_2 && data_2.key, ctx_2);
          r += '</div>\n            ';
          r += c(ctx_2.label, ctx_2);
          r += '\n          </div>\n          <div class="item-after">\n\t\t  ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.type == \'tel\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n            <i class="icon f7-icons if-ios">phone_fill</i>\n            <i class="icon material-icons if-md">call</i>\n\t\t  ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\t\t  ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.type == \'email\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n            <i class="icon f7-icons if-ios">email_fill</i>\n            <i class="icon material-icons if-md">email</i>\n\t\t  ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\t\t  ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.type == \'web\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n            <i class="icon f7-icons if-ios">language</i>\n            <i class="icon material-icons if-md">language</i>\n\t\t  ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\t\t  </div>\n        </div>\n\t\t</a>\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </ul>\n\t\t<p><a id="refreshContacts" class="button button-fill" @click="refreshContacts" style="display: none">Refresh Contacts</a></p>\n      </div>\n    </div>\n  </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    