export default {
  data: function () {
    return { products: this.$root.products };
  },
  methods: {
    forgottenPassword: function () {
    }
  },
  on: {
    pageAfterIn: function (e, page) {
      if (this.$app.sheet.get()) {
        this.$app.sheet.close();
      }
    }
  },
  id: '6a28bd655c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n\t<div class="page" data-name="forgotten-password">\n\t\t<div class="navbar">\n\t\t\t<div class="navbar-inner sliding">\n\t\t\t\t<div class="title">Forgotten Password</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="login-screens" id="forgotten-screen">\n\t\t\t<div class="view">\n\t\t\t\t\t<div class="page-content login-screen-content">\n\t\t\t\t\t\t<div class="logo"><div></div></div>\n\t\t\t\t\t\t<div class="inset">\n\t\t\t\t\t\t\t<div id="forgotten-error" class="alert alert-danger">\n\t\t\t\t\t\t\t\t<i class="fa fa-exclamation-triangle"></i> Please correct the following error(s):\n\t\t\t\t\t\t\t\t<div class="list virtual-list">\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="list">\n\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t<li class="item-content item-input">\n\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t<div class="item-title item-label">Email Address</div>\n\t\t\t\t\t\t\t\t\t\t<div class="item-input-wrap">\n\t\t\t\t\t\t\t\t\t\t\t<input type="email" name="username" placeholder="Your email address">\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="divider"><div class="left"></div><i class="fa fa-star"></i><i class="fa fa-star bigstar"></i><i class="fa fa-star"></i><div class="right"></div></div>\n\t\t\t\t\t\t<div class="list button-list">\n\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t<a href="#" class="item-link list-button forgotten-button button button-active">Request Password Change</a>\n\t\t\t\t\t\t\t\t</li>\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t<a href="#" class="item-link list-button link back cancel-button button button-active">Cancel</a>\n\t\t\t\t\t\t\t\t</li>\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};