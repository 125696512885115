var cordovaApp = {
	f7: null,
	/*
	This method hides splashscreen after 2 seconds
	*/
	handleSplashscreen: function() {
		var f7 = cordovaApp.f7;
		if (!window.navigator.splashscreen || f7.device.electron) return;
		setTimeout(() => {
			window.navigator.splashscreen.hide();
			}, 2000);
	},
	/*
	This method prevents back button tap to exit from app on android.
	And allows to exit app on backbutton double tap
	*/
	handleAndroidBackButton: function () {
		var f7 = cordovaApp.f7;
		if (f7.device.electron) return;
		cordovaApp.backButtonTimestamp = new Date().getTime();
		document.addEventListener('backbutton', function (e) {
		// double tap routine
		if (new Date().getTime() - cordovaApp.backButtonTimestamp < 250) {
			cordovaApp.backButtonTimestamp = new Date().getTime();
			cordovaApp.closeApp();
			return true;
		} else {
			// if a pop up or dialog is opened then the back button should close them first 
			if (f7.popup.get()) {
				f7.popup.close();
			} else {
				if (f7.dialog.get()) {
					f7.dialog.close();
				} else {		
					if (f7.sheet.get()) {
						f7.sheet.close();
					} else {
						// if the view is on the home screen then the back button then exit
						// otherwise just go back
						if (f7.view.current.index == 0 ) {
							if (f7.view.current.router.currentRoute.url == '/') {
							cordovaApp.closeApp();				
							} else {
								f7.view.main.router.back();
							}
						} else {
							// if the view is not the home screen then back button should direct the view to the homescreen
							f7.tab.show('#view-home');
							f7.views.main.router.navigate(f7.views.main.router.url, {reloadCurrent: true, ignoreCache: true});
						}
					}
				}
			}
		}
		cordovaApp.backButtonTimestamp = new Date().getTime();
		e.preventDefault();
		return false;
		}, false);
	},
	/*
	This method does the following:
	- provides cross-platform view "shrinking" on keyboard open/close
	- hides keyboard accessory bar for all inputs except where it required
	*/
	handleKeyboard: function () {
		var f7 = cordovaApp.f7;
		if (!window.Keyboard || !window.Keyboard.shrinkView || f7.device.electron) return;
		var $ = f7.$;
		window.Keyboard.shrinkView(false);
		window.Keyboard.disableScrollingInShrinkView(true);
		window.Keyboard.hideFormAccessoryBar(true);
		window.addEventListener('keyboardWillShow', () => {
			f7.input.scrollIntoView(document.activeElement, 0, true, true);
			});
		window.addEventListener('keyboardDidShow', () => {
			f7.input.scrollIntoView(document.activeElement, 0, true, true);
			});
		window.addEventListener('keyboardDidHide', () => {
			if (document.activeElement && $(document.activeElement).parents('.messagebar').length) {
				return;
			}
			window.Keyboard.hideFormAccessoryBar(false);
			});
		window.addEventListener('keyboardHeightWillChange', (event) => {
			var keyboardHeight = event.keyboardHeight;
			if (keyboardHeight > 0) {
				// Keyboard is going to be opened
				document.body.style.height = `calc(100% - ${keyboardHeight}px)`;
				$('html').addClass('device-with-keyboard');
				} else {
				// Keyboard is going to be closed
				document.body.style.height = '';
				$('html').removeClass('device-with-keyboard');
			}
			});
		$(document).on('touchstart', 'input, textarea, select', function (e) {
			var nodeName = e.target.nodeName.toLowerCase();
			var type = e.target.type;
			var showForTypes = ['datetime-local', 'time', 'date', 'datetime'];
			if (nodeName === 'select' || showForTypes.indexOf(type) >= 0) {
				window.Keyboard.hideFormAccessoryBar(false);
			} else {
				window.Keyboard.hideFormAccessoryBar(true);
			}
			}, true);	

	},
	handleNotifications: function() {
		var f7 = cordovaApp.f7;
		
		document.addEventListener('deviceready', () => {
			cordova.plugins.firebase.messaging.onMessage(function(payload) {
				var notificationClickToClose = f7.notification.create({
				  icon: '<i class="icon f7-icons">message</i>',
				  title:  payload.gcm.title,
				  titleRightText: 'now',
				  subtitle:  payload.gcm.body,
				  text: 'Click to close',
				  closeOnClick: true,
				});
				notificationClickToClose.open();
				
			});
			
			cordova.plugins.firebase.messaging.onBackgroundMessage(function(payload) {
				// This code will get called when the user clicks the Background Notifications
				// We could avoid using the Intent plugin
				if (typeof(payload.actionURL) !='undefined') {
					localStorage.setItem('redirect', payload.actionURL);
				}
				
				// Basically the app has already been started up and running in the background
				if (f7.data.userIsLoggedIn && f7.data.synced) {
					f7.data.synced = false; // force a resync
					f7.tab.show('#view-home');
					f7.views.main.router.navigate('/', {reloadCurrent: true, ignoreCache: true});
				}
			});				
		});		
		
	},
	handlePauseResume: function () {
		var f7 = cordovaApp.f7;
		document.addEventListener('deviceready', () => {

			document.addEventListener("pause", function(e) {
				f7.data.appPaused = true;
				}, false);

			document.addEventListener("resume", function(e) {
				f7.data.appPaused = false;
				}, false);
			
		});		

	},	
	closeApp: function () {
		var f7 = cordovaApp.f7;
		f7.dialog.confirm('Are you sure that you want to exit the application?',
		// Handle Dialog Response
		// On Confirm
		function () {
			if (window.navigator.app && window.navigator.app.exitApp) {
				window.navigator.app.exitApp();
			}
		},
		// On Cancel
		function() {
			// do nothing for now
		})	  
	},
	init: function (f7) {
		// Save f7 instance
		cordovaApp.f7 = f7;
		
		f7.data.deviceInformation = device;
		
		// Handle Notifications
		cordovaApp.handleNotifications();

		// Handle Android back button
		cordovaApp.handleAndroidBackButton();

		// Handle Statusbar
		cordovaApp.handleSplashscreen();

		// Handle Keyboard
		cordovaApp.handleKeyboard();
		

		// Handle Pause/Resume
		cordovaApp.handlePauseResume();

	},
};
export default cordovaApp;

