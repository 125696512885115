var webApp = {
	f7: null,
	/*
	This method prevents back button tap to exit from app on android.
	And allows to exit app on backbutton double tap
	*/
	handleLeave: function () {
		var f7 = webApp.f7;
		if (f7.device.electron) return;
		
		if (!f7.data.isMobile) {
			// This for security to log the user out on closing the browser
			window.addEventListener('unload', (event) => {
				var currentDate = new Date();
				var expiryDate = localStorage.getItem('rememberme');
				if (!expiryDate || (currentDate.getTime() > expiryDate)) {
					f7.methods.clearUserData();
				}
			});
		}
			
	},
	init: function (f7) {		
		// Save f7 instance
		webApp.f7 = f7;
		
		// Handle Leave
		webApp.handleLeave();
	},
};
export default webApp;




	
