
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var userIsLoggedIn = this.$app.data.userIsLoggedIn;
    return {
      userIsLoggedIn: userIsLoggedIn,
      consigNo: this.$root.consigNo,
      postcode: this.$root.postcode,
      parcels: this.$root.parcels
    };
  },
  methods: {
    refreshPage: function (e, done) {
      var that = this.$app;
      that.methods.ajaxRequest('mobile/rest/synchronise', {}, function () {
        done();
        that.data.synced = true;
        that.view.main.router.navigate('/', {
          reloadCurrent: true,
          ignoreCache: true
        });
      }, function () {
        done();
      });
    }
  },
  on: {
    pageInit: function (e, page) {
      if (this.$app.data.userIsLoggedIn && !this.$app.data.synced) {
        this.$app.methods.syncSettings();
      }
    },
    pageAfterIn: function (e, page) {
      var that = this.$app;
      if (that.data.userIsLoggedIn && that.data.synced) {
        if (localStorage.getItem('redirect')) {
          var redirectURL = localStorage.getItem('redirect');
          localStorage.removeItem('redirect');
          that.views.main.router.navigate(redirectURL, { ignoreCache: true });
        } else {
        }
      }
    }
  },
  id: '06c23d73da',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n\t<div class="page" data-name="home">\n\t\t<!-- Top Navbar -->\n\t\t<div class="navbar">\n\t\t\t<div class="navbar-inner">\n\t\t\t\t<div class="title">Tracked Parcels</div>\n\t\t\t</div>\t\t\n\t\t</div>\n\n\t\t<!-- Scrollable page content-->\n\t\t<div class="page-content ptr-content" data-ptr-distance="55" data-ptr-mousewheel="true" @ptr:refresh="refreshPage">\n\t\t\t<!-- Default pull to refresh preloader-->\n\t\t\t<div class="ptr-preloader">\n\t\t\t\t<div class="preloader"></div>\n\t\t\t\t<div class="ptr-arrow"></div>\n\t\t\t</div>\n\t\t\t';
      r += Template7Helpers.if.call(ctx_1, ctx_1.userIsLoggedIn, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t\t\t<div class="block parcels-title">\n\t\t\t\t<h2><i class="fa fa-cubes"></i> Parcels</h2>\n\t\t\t</div>\n\t\t\t';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t\t\t<div class="fab fab-extended fab-left-top color-blue">\n\t\t\t\t<a href="/login/"> \n\t\t\t\t<i class="icon f7-icons">enter</i>\n\t\t\t\t\t<div class="fab-text">Login</div>\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<br/><br/><br/><br/><br/>\n\t\t\t';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\t\t\t<div class="fab fab-extended fab-right-top color-red">\n\t\t\t\t<a class="sheet-open" href="#" data-sheet=".trackparcel">\n\t\t\t\t\t<i class="icon f7-icons">add</i>\n\t\t\t\t\t<div class="fab-text">Add Parcel</div>\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<div class="divider"><div class="left"></div><i class="fa fa-star"></i><i class="fa fa-star bigstar"></i><i class="fa fa-star"></i><div class="right"></div></div>\t\t\t\n\t\t\t<div class="list media-list inset">\n\t\t\t\t';
      r += Template7Helpers.if.call(ctx_1, ctx_1.parcels, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t\t\t\t<div class="alert alert-info">\n\t\t\t\t\t<i class="fa fa-info-circle"></i> Swipe left to DELETE parcels or VIEW images from the list below.\n\t\t\t\t</div>\n\t\t\t\t';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\t\t\t\t<ul>\n\t\t\t\t\t';
      r += Template7Helpers.each.call(ctx_1, ctx_1.parcels, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t\t\t\t\t<li class="consignment swipeout deleted-callback">\n\t\t\t\t\t\t<a href="/';
          r += Template7Helpers.js_if.call(ctx_2, 'this.isDelivered && this.hasImages', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'pod';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += 'parcel';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '/';
          r += c(ctx_2.systemRef, ctx_2);
          r += '/" class="item-link swipeout-content " id="';
          r += c(ctx_2.systemRef, ctx_2);
          r += '" target="">\n\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t<div class="item-title-row">\n\t\t\t\t\t\t\t\t\t<div class="item-title">';
          r += c(ctx_2.account, ctx_2);
          r += '</div>\n\t\t\t\t\t\t\t\t\t<div class="item-after">';
          r += c(ctx_2.deliveryDate, ctx_2);
          r += '</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class="item-subtitle">';
          r += c(ctx_2.consignmentNo, ctx_2);
          r += '</div>\n\t\t\t\t\t\t\t\t<div class="item-text">';
          r += c(ctx_2.parcelStatus, ctx_2);
          r += '\n\t\t\t\t\t\t\t\t';
          r += Template7Helpers.if.call(ctx_2, ctx_2.hasImages, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<i class="f7-icons">camera_fill</i>\n\t\t\t\t\t\t\t\t';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t<div class="swipeout-actions-right">\n\t\t\t\t\t\t\t\t<a href="#" class="my-swipeout-delete color-red">Delete</a>\n\t\t\t\t\t\t\t\t';
          r += Template7Helpers.if.call(ctx_2, ctx_2.hasImages, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\t\t\t\t\t\t\t\t<a href="/pod/';
              r += c(ctx_3.systemRef, ctx_3);
              r += '/" class="color-blue" id="';
              r += c(ctx_3.systemRef, ctx_3);
              r += '" target="">Images</a>\n\t\t\t\t\t\t\t\t';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</a>\n\t\t\t\t\t</li>\n\t\t\t\t\t';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\t\t\t\t</ul>\n\t\t\t\t';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.parcels, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t\t\t\t<div class="alert alert-info">\n\t\t\t\t\t<i class="fa fa-info-circle"></i> Start tracking your parcels by selecting "ADD PARCEL" above.\n\t\t\t\t</div>\n\t\t\t\t';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\t\t\t\t<br>\n\t\t\t\t<br>\n\t\t\t</div>\n\t\t</div>\t\n\t\t<div class="sheet-modal sheet-modal-top trackparcel" style="height: auto;">\n\t\t\t<div class="toolbar">\n\t\t\t\t<div class="toolbar-inner">\n\t\t\t\t\t<div class="left"></div>\n\t\t\t\t\t<div class="right"><a class="link sheet-close" href="#">Close</a></div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="sheet-modal-inner">\n\t\t\t\t<div class="page-content block">\n\t\t\t\t\t<h4>\n\t\t\t\t\t\t<i class="fa fa-cube"></i> Add Parcel\n\t\t\t\t\t</h4>\n\t\t\t\t\t<div class="list">\n\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t<li class="item-content item-input">\n\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t<div class="item-title item-label">Consignment Number</div>\n\t\t\t\t\t\t\t\t\t<div class="item-input-wrap">\n\t\t\t\t\t\t\t\t\t\t<input type="text" name="consignmentNo" placeholder="Consignment Number" value="';
      r += c(ctx_1.consigNo, ctx_1);
      r += '">\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t<li class="item-content item-input">\n\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t<div class="item-title item-label">Postcode</div>\n\t\t\t\t\t\t\t\t\t<div class="item-input-wrap">\n\t\t\t\t\t\t\t\t\t\t<input type="text" name="postcode" placeholder="Postcode" value="';
      r += c(ctx_1.postcode, ctx_1);
      r += '">\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t</ul>\n\t\t\t\t\t</div>\t\t\t\t\t\n\t\t\t\t\t<div class="divider"><div class="left"></div><i class="fa fa-star"></i><i class="fa fa-star bigstar"></i><i class="fa fa-star"></i><div class="right"></div></div>\n\t\t\t\t\t<a href="#" class="button button-active" id="track">Track</a>\t\t\t\t\t\t\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\t\n\t</div>\n\t\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    