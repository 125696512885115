
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var isMobile = this.$app.data.isMobile;
    return {
      products: this.$root.products,
      isMobile: isMobile
    };
  },
  methods: {},
  on: {
    pageAfterIn: function (e, page) {
      if (this.$app.sheet.get()) {
        this.$app.sheet.close();
      }
      this.$app.form.fillFromData('#login', JSON.stringify(localStorage));
      this.$app.$('#login-username').focus();
    }
  },
  id: 'b0946fcc2e',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n\t<div class="page" data-name="login">\n\t\t<div class="navbar">\n\t\t\t<div class="navbar-inner sliding">\n\t\t\t\t<div class="title">Login</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="login-screens" id="login-screen">\n\t\t\t<div class="view">\n\t\t\t\t\t<div class="page-content login-screen-content">\n\t\t\t\t\t\t<div class="logo"><div></div></div>\n\t\t\t\t\t\t<form id="login" class="form-store-data">\n\t\t\t\t\t\t\t<div class="list">\n\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t<li class="item-content item-input">\n\t\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t\t<div class="item-title item-label">Email Address</div>\n\t\t\t\t\t\t\t\t\t\t\t<div class="item-input-wrap">\n\t\t\t\t\t\t\t\t\t\t\t\t<input id="login-username" type="email" name="username" placeholder="Your email address">\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t\t<li class="item-content item-input">\n\t\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t\t<div class="item-title item-label">Password</div>\n\t\t\t\t\t\t\t\t\t\t\t<div class="item-input-wrap">\n\t\t\t\t\t\t\t\t\t\t\t\t<input id="login-password" type="password" name="password" placeholder="Your password">\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t\t';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.isMobile, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t\t<label class="item-checkbox item-content">\n\t\t\t\t\t\t\t\t\t\t\t<input type="checkbox" name="rememberme" value="yes"/>\n\t\t\t\t\t\t\t\t\t\t\t<i class="icon icon-checkbox" style="margin-left: 0.5em; margin-right: 0px"></i>\n\t\t\t\t\t\t\t\t\t\t\t<div class="item-inner item-label">\n\t\t\t\t\t\t\t\t\t\t\t\tRemember Me\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t</label>\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t\t';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</form>\n\t\t\t\t\t\t<div class="divider"><div class="left"></div><i class="fa fa-star"></i><i class="fa fa-star bigstar"></i><i class="fa fa-star"></i><div class="right"></div></div>\n\t\t\t\t\t\t<div class="list button-list">\n\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t<a href="#" class="item-link list-button login-button button button-active">Sign In</a>\n\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t<a href="/create/" class="item-link list-button button button-active">Create Account</a>\n\t\t\t\t\t\t\t\t</li>\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t<a href="/forgotten/" class="item-link list-button button button-active">Forgotten Password</a>\n\t\t\t\t\t\t\t\t</li>\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    