exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var getUrl = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL___0___ = getUrl(require("../fonts/MaterialIcons-Regular.eot"));
var ___CSS_LOADER_URL___1___ = getUrl(require("../fonts/MaterialIcons-Regular.woff2"));
var ___CSS_LOADER_URL___2___ = getUrl(require("../fonts/MaterialIcons-Regular.woff"));
var ___CSS_LOADER_URL___3___ = getUrl(require("../fonts/MaterialIcons-Regular.ttf"));
var ___CSS_LOADER_URL___4___ = getUrl(require("../fonts/Framework7Icons-Regular.eot"));
var ___CSS_LOADER_URL___5___ = getUrl(require("../fonts/Framework7Icons-Regular.woff2"));
var ___CSS_LOADER_URL___6___ = getUrl(require("../fonts/Framework7Icons-Regular.woff"));
var ___CSS_LOADER_URL___7___ = getUrl(require("../fonts/Framework7Icons-Regular.ttf"));
// Module
exports.push([module.id, "/* Material Icons Font (for MD theme) */\n@font-face {\n  font-family: 'Material Icons';\n  font-style: normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL___0___ + ");\n  src: local('Material Icons'),\n       local('MaterialIcons-Regular'),\n       url(" + ___CSS_LOADER_URL___1___ + ") format('woff2'),\n       url(" + ___CSS_LOADER_URL___2___ + ") format('woff'),\n       url(" + ___CSS_LOADER_URL___3___ + ") format('truetype');\n}\n.material-icons {\n  font-family: 'Material Icons';\n  font-weight: normal;\n  font-style: normal;\n  font-size: 24px;\n  display: inline-block;\n  line-height: 1;\n  text-transform: none;\n  letter-spacing: normal;\n  word-wrap: normal;\n  white-space: nowrap;\n  direction: ltr;\n  -webkit-font-smoothing: antialiased;\n  text-rendering: optimizeLegibility;\n  -moz-osx-font-smoothing: grayscale;\n  -moz-font-feature-settings: 'liga';\n       font-feature-settings: 'liga';\n}\n\n/* Framework7 Icons Font (for iOS theme) */\n@font-face {\n  font-family: 'Framework7 Icons';\n  font-style: normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL___4___ + ");\n  src: url(" + ___CSS_LOADER_URL___5___ + ") format(\"woff2\"),\n       url(" + ___CSS_LOADER_URL___6___ + ") format(\"woff\"),\n       url(" + ___CSS_LOADER_URL___7___ + ") format(\"truetype\");\n}\n.f7-icons {\n  font-family: 'Framework7 Icons';\n  font-weight: normal;\n  font-style: normal;\n  font-size: 28px;\n  line-height: 1;\n  letter-spacing: normal;\n  text-transform: none;\n  display: inline-block;\n  white-space: nowrap;\n  word-wrap: normal;\n  direction: ltr;\n  -webkit-font-smoothing: antialiased;\n  text-rendering: optimizeLegibility;\n  -moz-osx-font-smoothing: grayscale;\n  -moz-font-feature-settings: \"liga=1\";\n  -moz-font-feature-settings: \"liga\";\n  font-feature-settings: \"liga\";\n  text-align: center;\n}\n", ""]);
