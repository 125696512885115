import HomePage from '../pages/home.f7.html';
import HomePageW from '../pages/home.w.f7.html';
import LoginPage from '../pages/login.f7.html';
import CreatePage from '../pages/create.f7.html';
import ForgottenPage from '../pages/forgotten.f7.html';
import ContactUsPage from '../pages/contact.f7.html';
import ParcelPage from '../pages/parcel.f7.html';
import SettingsPage from '../pages/settings.f7.html';
import NotFoundPage from '../pages/404.f7.html';

var routes =
	[
		{
			path: '/',
			async(routeTo, routeFrom, resolve, reject) {
				// Router instance
				var router = this;
				// App instance
				var app = router.app;
				
				// Handle Get Query
				var query = app.utils.parseUrlQuery(router.url);
				var consignmentno = false;
				var deliverypostcode = false;
				
				// check for query
				if (Object.keys(query).length) {
					for (const property in query) {
						switch(property.toLowerCase()) {
							case 'c':
								var consignmentno =	query[property];
								break;
							case 'code':
								// replace + sign to space to support x-www-form-urlencoded
								var deliverypostcode = (query[property]).replace(/\+/g, ' ');
							break;
							default:
							// code block
						} 
					}
					
					if (consignmentno && deliverypostcode && !app.data.addingParcel) {
						app.data.addingParcel = true;
						app.methods.addParcel(consignmentno, deliverypostcode, (data) => {
							app.data.addingParcel = false;
							if(data.status == 'success'){
								window.history.pushState(null, null, "/");
								// force refresh
								app.view.main.router.navigate('/', {reloadCurrent: true, ignoreCache: true});
								if (localStorage.getItem('redirect')) {
									var redirectURL = localStorage.getItem('redirect');
									localStorage.removeItem('redirect');
									app.view.main.router.navigate(redirectURL, {ignoreCache: true});
								}
							}
						});						
					}
				}
				
				if (router.app.data.isMobile) {
					resolve({ component: HomePage });
				} else {
					resolve({ component: HomePageW });
				}
			}	
		},
		{
			path: '/login/',
			component: LoginPage
		},
		{
			path: '/create/',
			component: CreatePage
		},
		{
			path: '/forgotten/',
			component: ForgottenPage
		},
		{
			path: '/contact/',
			component: ContactUsPage
		},
		{
			path: '/parcel/:id/',
			component: ParcelPage
		},
		{
			path: '/pod/:id/',
			async(routeTo, routeFrom, resolve, reject) {
				// Router instance
				var router = this;

				// App instance
				var app = router.app;
				var parcel = app.methods.getParcel(routeTo.params.id);
				var photos = [];				

				if (parcel) {

					if (parcel.podImages && parcel.podImages.length) {
						parcel.podImages.forEach(function(image) {
							photos.push ({url: image.path, caption: image.dateAdded + ' ' + image.timeAdded});
						});
					}						
					
					if (parcel.consignmentImages && parcel.consignmentImages.length) {
						parcel.consignmentImages.forEach(function(image) {
							photos.push ({url: image.path, caption: image.dateAdded + ' ' + image.timeAdded});
						});					
					} 
				} 
				if (photos.length) {
					app.myPhotoBrowserPopup = app.photoBrowser.create({
					photos : photos,
					type: 'popup',
					theme: 'dark',
					backLinkText: 'Back',
						on: {
							closed: function () {
								this.app.view.main.router.navigate('/', {reloadCurrent: true, ignoreCache: true})
							}
						}
					});
					app.myPhotoBrowserPopup.open();
				} else {
					resolve({ component: ParcelPage });
				}
			}	
		},
		{
			path: '/settings/',
			component: SettingsPage
		},
		{
			path: '/logout/',
			async(routeTo, routeFrom, resolve, reject) {
				// Router instance
				var router = this;

				// App instance
				var app = router.app;

				app.dialog.confirm('Are you sure that you want to logout?',
					// Handle Dialog Response
					// On Confirm
					function () {
						app.methods.logOut();
					},
					// On Cancel
					function() {
						resolve({ url: '/settings/' });
					});	  
			}
		},  
		{
			path: '(.*)',
			component: NotFoundPage
		}
	];

export default routes;