// Import Dom7 and F7
import $$ from 'dom7';
import Framework7 from './framework7-custom.js';


// Import F7 Styles
import '../css/framework7-custom.less';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import Leaflet Custom Styles
import '../css/leaflet/leaflet.css';

// Import IntroJS Custom Styles
import '../css/introjs/introjs.css';
import '../css/introjs/themes/introjs-modern.css';

// Import Cordova APIs
import cordovaApp from './cordova-app.js';

// Import WebApp APIs
import webApp from './web-app.js';


// Import Routes
import routes from './routes.js';

// Import Leaflet.js for Map functionality
import './leaflet/leaflet.js';

// Import Intro.js for Demo functionality
import introJs from './introjs/intro.js';


// Load the Application Settings
const env = process.env.NODE_ENV || 'development';
const appSettings = require('./configs/' + env + '.config.json');
const appContacts = require('./configs/contacts.config.json');
const appDefaults = require('./configs/defaults.config.json');

const timerOn = true;
// Auto refresh every 30 seconds
const timerInterval = 30000;


// Load Crypto Node plugin
const crypto = require('crypto');
const passwordValidator = require('password-validator');

var schema = new passwordValidator();
 
// Add properties to it
schema
.is().min(8)                                    // Minimum length 8
.is().max(100)                                  // Maximum length 100
.has().uppercase()                              // Must have uppercase letters
.has().lowercase()                              // Must have lowercase letters
.has().digits()                                 // Must have digits
.has().not().spaces();                          // Should not have spaces

 

const leafletIcons =
	{ 
		delivery: require('../css/leaflet/images/delivery.png'),
		driver: require('../css/leaflet/images/device.png')
	};
	
// Create App Instance
var app = new Framework7(
	{
		root: '#app', // App root element
		id: 'uk.co.sysx.xdp.deliveryx', // App bundle ID
		name: 'XDP', // App name
		theme: 'auto', // Automatic theme detection
		pushState: true,
		history: true,
		stackPages: true,
		touch: {
			tapHold: true,
			fastClicks:true,
			materialRipple:true,
			activeState: true,
			disableContextMenu: false,
		},		
		// App root data
		data: function () {
			return {
				appBuild : 1,
				mode: process.env.NODE_ENV,
				ajaxURL : appSettings.ajaxURL,
				//ajaxURL : "https://xdp.sysx.co.uk/",
				contacts: appContacts.contacts,
				appDefaults: appDefaults,
				appPaused: false,
				secretKey: 'IPrkLEVrp97z0B9rYaHp',
				thirdPartyEmail: 'trackingupdates@xdp.co.uk',
				userIsLoggedIn: (localStorage.getItem('userIsLoggedIn') == 'true'),
				isMobile: false,
				profile: JSON.parse(localStorage.getItem('profile')),
				parcels: JSON.parse(localStorage.getItem('parcels')),
				leafletIcons: leafletIcons,
				synced: false,
				deviceInformation: {uuid: 'web'},
				consigNo: '',
				postcode: '',
				leftSafe: '',
				notificationsEnabled: false,
				leftSafeOptions: false,
				addingParcel: false,
				syncFailed: false,
				};
		},	
		// App root methods
		methods: {
			getSettings: function () {
				alert(this.data.ajaxURL);
			},
			loadPageSettings: function () {
				var that = this;
				var sessionContacts = localStorage.getItem('contacts');
				var sessionTerms = localStorage.getItem('termsandconditions');

				that.methods.getAppInfo(
					function(data) 
					{
						if (data.status == 'success'){
							if (sessionTerms !=  data.alert.terms) {
								console.log ("Terms Changed");
								localStorage.setItem('termsandconditions', data.alert.terms); 
								$$('#refreshTermsAndConditions').trigger('click');
							}
							if (sessionContacts != JSON.stringify(data.alert.contacts)) {
								console.log ("Contacts Changed");
								localStorage.setItem('contacts', JSON.stringify(data.alert.contacts));
								$$('#refreshContacts').trigger('click');
							}
							if (data.alert.contacts && Object.keys(data.alert.contacts).length) {
								$$(".tab-link[href='#view-contact']").show();
							} else {
								$$(".tab-link[href='#view-contact']").hide();
							}
							if (data.alert.leftsafe) {
								that.data.leftSafe = data.alert.leftsafe;
							}
							if (data.alert.leftsafeoptions) {
								that.data.leftSafeOptions = data.alert.leftsafeoptions;
							}
							if (data.alert.thirdparyemail) {
								that.data.thirdPartyEmail = data.alert.thirdparyemail;
							}
						}
					}
				);
				
				Object.keys(that.data.appDefaults).forEach(function(key, setting) {
					if (!that.form.getFormData(key)) {
						localStorage.setItem('f7form-' + key, JSON.stringify(that.data.appDefaults[key]));
						that.form.fillFromData('#' + key, that.data.appDefaults[key]);
					} 
				});
				that.data.notificationsEnabled = that.methods.notificationsEnabled();

			},
			hashPassword: function (password) {
				 return crypto.createHash('sha512')
                   .update(password + app.data.secretKey)
                   .digest('hex');
			},
			validatePassword: function (password, retypepassword) {

				// Validate against a password string
				var errors = [];
			
				var result = schema.validate(password, { list: true });
				if (result.length) {
					result.forEach(function(e) {
						var errorText = '';
						switch (e) {
							case 'min':
								errorText = 'Password must be at least 8 character';
								break;
							case 'max':
								errorText = 'Password can not be longer than 100 characters';
								break;
							case 'uppercase':
								errorText = 'Password must contain at least 1 uppercase character';
								break;
							case 'lowercase':
								errorText = 'Password must contain at least 1 lowercase character';
								break;
							case 'digits':
								errorText = 'Password must contain at least 1 digit';
								break;
							case 'spaces':
								errorText = 'Password must not contain any spaces';
								break;
							default:
								errorText = e;
						}
						errors.push(errorText);
					});
				}

				if (typeof(retypepassword) != "undefined" && retypepassword && retypepassword != password) {
					errors.push('The entered Passwords do not match');
				}
								
				return errors;
			},
			validEmail: function(emailAddress) {
                var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
                return pattern.test(emailAddress);
			},
			showErrors: function(id, errors) {
				var that = this;
				if (!errors.length) {
					$$('#' + id).hide();
				} else {
					$$('#' + id).show();
					var virtualList = that.virtualList.create({
						// List Element
						el: '#' + id + ' .list',
						// Pass array with items
						items: errors,
						// Custom search function for searchbar
						// List item Template7 template
						itemTemplate:
							'<li>' +
								'{{this}}' +
							'</li>',
						// Item height
						//height: app.theme === 'ios' ? 63 : (app.theme === 'md' ? 73 : 46),
						height: 26 
					});
				}
			},				
			ajaxRequest: function (endPoint, extraData, successCallBack, errorCallBack) {
				var that = this;
				
				that.preloader.show();
				
				if (that.device.cordova && that.methods.notificationsEnabled()) {
					cordova.plugins.firebase.messaging.getToken().then(
					// got token
						function(token) { 
							that.methods.ajaxRequestWithToken(endPoint, token, extraData, successCallBack, errorCallBack);
						},									
						function(error) { 
							var toastBottom = that.toast.create({
							  text: 'Connection Failed <br\>' + error ,
							  closeTimeout: 2000,
							});
							toastBottom.open();
							that.preloader.hide();
						},									
					)
				} else {
					that.methods.ajaxRequestWithToken(endPoint, '', extraData, successCallBack, errorCallBack);
				}				
				
			},
			ajaxRequestWithToken: function (endPoint, token, extraData, successCallBack, errorCallBack) {
				var that = this;
				var offlineParcels = [];

				var data = {
								'username': localStorage.getItem('username'), 
								'password': localStorage.getItem('password'),
								'deviceId': that.data.deviceInformation.uuid,
								'token': token,
								'appbuild': that.data.appBuild
							};

				if (endPoint == 'mobile/rest/synchronise' && !that.data.userIsLoggedIn && that.data.parcels) {
					(that.data.parcels).forEach(function(parcel, key) {
						offlineParcels.push({'consigNo' : parcel.consignmentNo, 'deliveryPostcode': parcel.postcode});
					})
					if (offlineParcels.length > 0) {
						Object.assign(data, {'offlineConsignments': offlineParcels});
					}
				}
				
				if (typeof(extraData) != "undefined") {
					Object.assign(data, extraData);
				}
				
				// make the rest call
				that.request.post(that.data.ajaxURL + endPoint, data, 
					function (data) {
						if (data.status == 'success') {
							that.data.syncFailed = false;
							if (endPoint != 'mobile/rest/registerUser' && endPoint != 'mobile/rest/forgotPassword' && endPoint != 'mobile/rest/getTerms' && endPoint != 'mobile/rest/getAppInfo') {
								if (typeof(data.profile) != 'undefined') {
									localStorage.setItem('userIsLoggedIn', true);
								}
							}
							
							if (typeof(data.profile) != 'undefined' && !data.profile) {
								localStorage.setItem('profile', JSON.stringify(data.profile));
								localStorage.setItem('displayname', data.profile.displayname);
							}
							
							if (typeof(data.addconsignment) != 'undefined') {
								// remove if the consignment already exists

								if (that.data.parcels) {
									(data.addconsignment).forEach(function(addConsignment, addkey) {
										if (that.data.parcels) {
											(that.data.parcels).forEach(function(parcel, key) {
												if (parcel.systemRef == data.addconsignment[addkey].systemRef) {
													that.data.parcels.splice(key, 1);
												}	
											})
										}
									});

									(data.addconsignment).forEach(function(addConsignment, addkey) {
										that.data.parcels.unshift(data.addconsignment[addkey]);
									})
								} else {
									that.data.parcels = data.addconsignment;
								}

								(data.addconsignment).forEach(function(addConsignment, addkey) {
									if (!localStorage.getItem('redirect')) {
										localStorage.setItem('redirect', '/parcel/' + data.addconsignment[addkey].systemRef + '/');
									}
								});
								
								that.data.consigNo = '';
								that.data.postcode = '';
								localStorage.setItem('parcels', JSON.stringify(that.data.parcels));
							} else {
								if (typeof(data.consignments) != 'undefined') {
									localStorage.setItem('parcels', JSON.stringify(data.consignments));
								}
							}

							if (endPoint != 'mobile/rest/getTerms' && endPoint != 'mobile/rest/registerUser' && endPoint != 'mobile/rest/getAppInfo') {
								var toastBottom = that.toast.create({
								  text: data.alert,
								  closeTimeout: 2000,
								});
								toastBottom.open();
							} else {
								if (endPoint == 'mobile/rest/registerUser') {
									that.dialog.alert(data.alert);
								}
							}
						} else {
							that.data.syncFailed = true;
							if (endPoint == 'mobile/rest/synchronise') {
								localStorage.setItem('userIsLoggedIn', false);
								localStorage.setItem('profile', JSON.stringify([]));
								localStorage.setItem('parcels', JSON.stringify([]));
							}
							
							var errorMsg = data.reason;
							
							if (typeof(data.errors) != 'undefined') {
								errorMsg = errorMsg + '<br\>' + data.errors.join('<br\>');
							}
							
							if (endPoint == 'mobile/rest/synchronise') {
									that.dialog.alert(errorMsg);
							} else {
								// Create bottom toast
								var toastBottom = that.toast.create({
								  text: errorMsg,
								  closeTimeout: 2000,
								});
								toastBottom.open();
							}
						}
						that.data.userIsLoggedIn = (localStorage.getItem('userIsLoggedIn') == 'true');
						that.data.profile = JSON.parse(localStorage.getItem('profile'));
						that.data.parcels = JSON.parse(localStorage.getItem('parcels'));
						
						if (typeof(successCallBack) != 'undefined') {
							successCallBack(data);
						}
						that.preloader.hide();
					},
					function (xhr, status) {
							var toastBottom = that.toast.create({
							  text: 'Connection Failed <br\>' + xhr ,
							  closeTimeout: 2000,
							});
							toastBottom.open();
							if (typeof(errorCallBack) != 'undefined') {
								errorCallBack();
							}							
							that.preloader.hide();

					}, 'json');						
					return true;
			},
			syncSettings: function () {
				var that = this;

				return that.methods.ajaxRequest('mobile/rest/synchronise', {}, function() {that.data.synced = true; if (!that.data.syncFailed) {that.view.main.router.navigate('/', {reloadCurrent: true, ignoreCache: true});}});
			},
			getParcel: function(systemRef) {
				var that = this;
				var currentParcel;
				
				(that.data.parcels).forEach(function(parcel) {
					if (parcel.systemRef == systemRef) {
						currentParcel = parcel;
					}	
				});	
				
				return currentParcel;
			},
			refreshDriver: function () {
				var that = this;
				var parcel = that.methods.getParcel(that.data.currentparcel);
				var padding = 80;
				
				if (!that.data.isMobile) {
					padding = 60;
				}

				var driverLat = parcel.delLat;
				var driverLong = parcel.delLong;
				
				if (parcel.drvLong) {
					driverLat = parcel.drvLat;
					driverLong = parcel.drvLong;
				}
				
				var driverLatLng = new L.LatLng(driverLat, driverLong);
				var delLatLng = new L.LatLng(parcel.delLat, parcel.delLong);
				var boundLatLng = new L.LatLngBounds(driverLatLng, delLatLng);

				if (parcel.drvLong) {
					that.data.mapdriver.addTo(that.data.map);
					that.data.mapdriver.setLatLng(driverLatLng);
					that.data.mapdriver.openPopup();
				} else {
					that.data.mapdriver.remove();
				}

				var deliveryInfo = JSON.parse(parcel.deliveryInfo);
				if (deliveryInfo.message) {
					// Set message
					$$('#deliveryInfo div').addClass(deliveryInfo.class);
					$$('#deliveryInfo div i.fa').addClass(deliveryInfo.icon);
					$$('#deliveryInfo div .message').html(deliveryInfo.message);
					if (deliveryInfo.progress) {
						$$('#progressBar i.fas').addClass('fa-flag-checkered');
						$$('#progressBar').show();
						$$('#conProgress').val(deliveryInfo.progress);
					}
					$$('#deliveryInfo div .lastupdated').hide();
					if (deliveryInfo.dateTime) {
						$$('#deliveryInfo div .lastupdated').html('<em>Last updated ' + deliveryInfo.dateTime + '</em>');
						$$('#deliveryInfo div .lastupdated').show();
					} 
					$$('#deliveryInfo').show();	
				} else {
					// Clear message
					$$('#deliveryInfo').hide();	
					$$('#deliveryInfo div').removeAttr('class').addClass('alert');
					$$('#deliveryInfo div i').removeAttr('class').addClass('fa');
					$$('#deliveryInfo div .message').html('');
					$$('#deliveryInfo div .lastupdated').html('');
				}
				
				that.data.mapdestination.openPopup();
				
				that.data.map.fitBounds(boundLatLng, {padding: [padding, padding]});
			},			
			addParcel: function(consigNo, postcode, successCallBack, errorCallBack) {
				var that = this;
				that.data.consigNo = consigNo;	
				that.data.postcode = postcode;
				that.methods.ajaxRequest('mobile/rest/addConsignment', {'consigNo': consigNo, 'postcode': postcode}, 
					// on success
					successCallBack,
					// on fail
					errorCallBack
					);
			},
			deleteParcel: function(systemRef, successCallBack, errorCallBack) {
				var that = this;	
				that.methods.ajaxRequest('mobile/rest/deleteConsignment', {'systemRef': systemRef}, 
					// on success
					function(data){
						if (typeof(successCallBack) != 'undefined') {
							successCallBack();
						}

						(that.data.parcels).forEach(function(parcel, key) {
							if (parcel.systemRef == systemRef) {
								that.data.parcels.splice(key, 1);
								localStorage.setItem('parcels', JSON.stringify(that.data.parcels));
								that.dialog.alert('Consignment has been removed from your list');
							}	
						});
					},
					// on fail
					function(){
						if (typeof(errorCallBack) != 'undefined') {
							errorCallBack();
						}
					}
				);
			},
			deleteOfflineParcel: function(systemRef, removeInstructions) {
				var that = this;	
				if (typeof(removeInstructions) != 'undefined') {
					removeInstructions();
				}
				(that.data.parcels).forEach(function(parcel, key) {
					if (parcel.systemRef == systemRef) {
						that.data.parcels.splice(key, 1);
						localStorage.setItem('parcels', JSON.stringify(that.data.parcels));
						that.dialog.alert('Consignment has been removed from your list');
					}	
				});
			},
			getAppInfo: function(successCallBack, errorCallBack) {
				var that = this;	
				that.methods.ajaxRequest('mobile/rest/getAppInfo', {}, 
					// on success
					successCallBack,
					// on fail
					errorCallBack
				);
			},	
			createUser: function(displayName, successCallBack, errorCallBack) {
				var that = this;	
				that.methods.ajaxRequest('mobile/rest/registerUser', {'displayName': displayName}, 
					// on success
					successCallBack,
					// on fail
					errorCallBack
				);
			},			
			forgotPassword: function(successCallBack, errorCallBack) {
				var that = this;	
				that.methods.ajaxRequest('mobile/rest/forgotPassword', {}, 
					// on success
					successCallBack,
					// on fail
					errorCallBack
				);
			},			
			changeDisplayName: function(displayName, successCallBack, errorCallBack) {
				var that = this;	
				that.methods.ajaxRequest('mobile/rest/changeDisplayName', {'displayName': displayName}, 
					// on success
					successCallBack,
					// on fail
					errorCallBack
				);
			},
			changeEmailAddress: function(emailAddress, successCallBack, errorCallBack) {
				var that = this;	
				that.methods.ajaxRequest('mobile/rest/changeEmailAddress', {'emailAddress': emailAddress}, 
					// on success
					successCallBack,
					// on fail
					errorCallBack
				);
			},
			changePassword: function(newPassword, successCallBack, errorCallBack) {
				var that = this;	
				that.methods.ajaxRequest('mobile/rest/changePassword', {'newPassword': newPassword}, 
					// on success
					successCallBack,
					// on fail
					errorCallBack
				);
			},

			changeDelivery: function (systemRef, postcode, deliveryDate, deliveryNotes, successCallBack, errorCallBack) {

				var that = this;	
				that.methods.ajaxRequest('mobile/rest/changeDelivery', 
					{
						'id': btoa(systemRef),
						'postcode': postcode,
						'deliveryDate': deliveryDate,
						'deliveryNotes': deliveryNotes,
					}, 
					// on success
					function(){
						if (typeof(successCallBack) != 'undefined') {
							successCallBack();
						}
					},
					// on fail
					function(){
						if (typeof(errorCallBack) != 'undefined') {
							errorCallBack();
						}
					}
				);				
			},
			addInstructions: function (systemRef, postcode, deliveryNotes, successCallBack, errorCallBack) {
				var that = this;	
				that.methods.ajaxRequest('mobile/rest/addInstructions', 
					{
						'id': btoa(systemRef),
						'postcode': postcode,
						'deliveryNotes': deliveryNotes,
					}, 
					// on success
					function(){
						if (typeof(successCallBack) != 'undefined') {
							successCallBack();
						}
					},
					// on fail
					function(){
						if (typeof(errorCallBack) != 'undefined') {
							errorCallBack();
						}
					}
				);				
			},
			changeNotificationSettings: function (notification, e, successCallBack, errorCallBack) {

				var that = this;	
				that.methods.ajaxRequest('mobile/rest/changeNotifications', 
					{
						'notfications': notification,
					}, 
					// on success
					function(data){
						// if it fails then set it back
						if (data.status == 'fail') {
							if (typeof(e) != 'undefined') {
								e.prop('checked', !notification);
							}
						} else {
							that.data.notificationsEnabled = notification;
						}
						if (typeof(successCallBack) != 'undefined') {
							successCallBack(data);
						}
						
					},
					// on fail
					function(){
						// if it fails then set it back
						if (typeof(e) != 'undefined') {
							e.prop('checked', !notification);
						}						
						if (typeof(errorCallBack) != 'undefined') 
						{
							errorCallBack();
						}
					}
				);				
			},
			
			logOut: function (successCallBack, errorCallBack) {
				var that = this;
				that.methods.ajaxRequest('mobile/rest/logOut', 
					{}, 
					// on success
					function(data){
						// if it fails then set it back
						if (data.status == 'success') {
							that.methods.clearUserData();
							that.tab.show('#view-home');
							that.views.main.router.navigate(that.views.main.router.url, {reloadCurrent: true, ignoreCache: true});
						}
						if (typeof(successCallBack) != 'undefined') {
							successCallBack(data);
						}
						
					},
					// on fail
					function(){
						// if it fails then set it back
						if (typeof(e) != 'undefined') {
							e.prop('checked', !notification);
						}						
						if (typeof(errorCallBack) != 'undefined') 
						{
							errorCallBack();
						}
					}
				);				


			},
			clearUserData: function () {
				var that = this;
				that.form.removeFormData('#login');
				localStorage.clear();
				localStorage.setItem('userIsLoggedIn', false);							
				that.data.userIsLoggedIn = (localStorage.getItem('userIsLoggedIn') == 'true');
				that.data.profile = [];
				that.data.parcels = [];
				that.form.storeFormData('login', {'username':'', 'password':'', 'rememberme': []});
			},
			
			onTimer: function() {
				var that = this;
				
				// dont run timer if the app is running in background mode or if the app has not been fully initialised
	
				if (that.data.appPaused || typeof(that.view.current) == 'undefined' || ! timerOn) { return; }

				var currentView = (that.view.current.router.currentRoute.url.split("/"))[1];
								
				// timer to do different things depending on which screen the user is in
				
				switch(currentView) {
					case '':
					// home screen
					break;
					case 'parcel':
						// dont run sync process if there is a popup or sheet dialog open
						if (that.sheet.get() || that.popup.get())
						{
							return;
						}
						that.methods.ajaxRequest('mobile/rest/synchronise', {}, function() {that.methods.refreshDriver();});
					
					// code block
					break;
					default:
					// code block
				} 
				
			},
			notificationsEnabled: function () {
				var that = this;
				
				if (typeof(that.view.current) != 'undefined' && that.view.current.router.currentRoute.url == '/settings/') {
					return $$('#notification-settings input[type=checkbox][name=enableNotification]').prop('checked');
				}
				
				if (localStorage.getItem('f7form-enableNotifications')) {
					return JSON.parse(localStorage.getItem('f7form-enableNotifications')).enableNotification[0] == 'yes';
				}
				
				return false;
			},
			startDemo: function() {
				var that = this;
				var intro = introJs();				
				intro.setOptions({
					'showStepNumbers': false,
					'overlayOpacity': 0.5
					});
				intro.start(that.data.currentPage);
			}	
		},
	// App routes
		routes: routes,

		// Input settings
		input: {
			scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
			scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
		},
		// Cordova Statusbar settings
		statusbar: {
			enabled: true,
			overlay: Framework7.device.cordova && Framework7.device.ios || 'auto',
			iosOverlaysWebView: false,
			iosBackgroundColor: '#222222',
			iosTextColor: 'white',
			androidOverlaysWebView: false,
			androidBackgroundColor: '#222222',
			androidTextColor: 'white'
		},		
		on: {
			init: function () {
				var f7 = this;
				f7.data.isMobile = (f7.device.cordova || navigator.userAgent.match('Android|iPad|iPhone'));
				f7.methods.loadPageSettings();
				
				if (f7.device.cordova) {
					// Init cordova APIs (see cordova-app.js)
					cordovaApp.init(f7);
				} else {
					webApp.init(f7);
				}

				// Show app
				$$('#app').show();
				
				f7.timerProcess = setInterval(() => {f7.methods.onTimer()}, timerInterval);
			},
			pageBeforeIn: function(page) {
				var f7 = this;
				f7.data.currentPage = page.name;
				clearInterval(f7.timerProcess);
				f7.timerProcess = setInterval(() => {f7.methods.onTimer()}, timerInterval);
			},
			pageAfterIn: function(page) {
				var f7 = this;

			},			
			pageAfterOut: function(page) {
				var f7 = this;
			},
			pageInit: function(page) {
				var f7 = this;
				// this has to be put in because Framework7 does nothing with the Enter Key
				if (f7.device.cordova) {
					$$('input').off('keyup');
					$$('input').on('keyup' , function(e) {
						if (e.keyCode == 13) {
							$$('input:focus').blur().next('input').focus();
						}
					});
				}

				
				// Clicking the Home Button should bring up the Home Screen
				$$('.tab-link').off('click');
				$$(".tab-link").on('click', function( event, ui ) 
				{	
					var targetURL = $$('.tab-link-active').attr('href');
				   // do whatever you want here, like alert a message!
				   if (targetURL == '#view-home') {
					   f7.views.main.router.navigate('/', {ignoreCache: true});
				   } else {
					   if (targetURL == '#view-settings') {
							if (localStorage.getItem('userIsLoggedIn') && localStorage.getItem('userIsLoggedIn') == 'true') {
								$$('.loggedin').show();
							} else {
								$$('.loggedin').hide();
							}
					   }						
				   }
				});				

				// this is to ensure that the pages only get binded with the DOM Event Listener once

				if (page.name == 'login') {

					// Detect auto fill user name and password
					$$("#login-password").on('blur', function (e) {
							if ($$("#login-username").val() != '' && $$("#login-password").val() != '' && f7.data.isMobile) {
								$$('.login-button').focus();
								$$('.login-button').trigger('click');
							}
					});
					
					$$('#login-password').keypress(function(event){
						var keycode = (event.keyCode ? event.keyCode : event.which);
						if(keycode == '13'){
							if ($$("#login-username").val() != '' && $$("#login-password").val() != '') {
								$$('.login-button').focus();
								$$('.login-button').trigger('click');
							}
						}
					});
					
					$$('.login-button').on('click', function () {
						var username = $$("#login #login-username").val(); 
						var password = $$("#login #login-password").val();
						var errors = [];
						
						if (!username) {
							errors.push('Username can not be blank');
						}
						
						if (!password) {
							errors.push('Password can not be blank');
						}
						if (errors.length > 0) {
							var toastBottom = f7.toast.create({
							  text: errors.join('<br\>'),
							  closeTimeout: 2000,
							});
							toastBottom.open();
							return false;
						}
						localStorage.setItem('username', username);
						localStorage.setItem('password', f7.methods.hashPassword(password));

						if ($$('#login-screen input[name=rememberme]').length) {
							var rememberme = $$('#login-screen input[name=rememberme]:checked').val();
							if (rememberme == 'yes') {
								// expire seven days from today
								var currentDate = new Date();
								var expiryDate = currentDate.getTime() + 7 * 24 * 60 * 60 * 1000;
								localStorage.setItem('rememberme', expiryDate);
							} else {
								localStorage.removeItem('rememberme');
							}
						}
					
						return f7.methods.syncSettings();
					});					
				}
				
				if (page.name == 'home') {
					
					// track options
					$$('#track').on('click', function (e) {
						var consigNo = $$('input[name=consignmentNo]').val();
						var postcode = $$('input[name=postcode]').val();
						var errors = [];
						
						if (!consigNo) {
							errors.push('Consignment number can not be blank');
						}
						
						if (!postcode) {
							errors.push('Postcode can not be blank');
						}
						if (errors.length > 0) {
							var toastBottom = f7.toast.create({
							  text: errors.join('<br\>'),
							  closeTimeout: 2000,
							});
							toastBottom.open();
							return false;
						}
						
						f7.methods.addParcel(consigNo, postcode, (data) => {
							if(data.status == 'success') { 
								f7.sheet.close(); 
								// force refresh before redirect
								f7.view.main.router.navigate('/', {reloadCurrent: true, ignoreCache: true});
								if (localStorage.getItem('redirect')) {
									var redirectURL = localStorage.getItem('redirect');
									localStorage.removeItem('redirect');
									f7.view.main.router.navigate(redirectURL, {ignoreCache: true});
								}
							}
						});
					});
					
					$$('.swipeout').on('swipeout:swipeenter', function(e) {
						$$('input:focus').blur();
						
					});
					// swipe delete handler
					$$('.my-swipeout-delete').on('click', function (e) {
						// had to use this bodge 
						const swipeoutEl = $$(e.target).closest('.swipeout');
						
						var id = $$(swipeoutEl).find('a').attr('id');
						if (swipeoutEl.length === 0) return;
						app.dialog.confirm('Are you sure you want to delete this consignment?',
							() => {
								if (f7.data.userIsLoggedIn) {
									f7.methods.deleteParcel(id, () => {app.swipeout.delete(swipeoutEl);});
								} else {
									f7.methods.deleteOfflineParcel(id, () => {app.swipeout.delete(swipeoutEl);});
								}
							},
							() => {
								app.swipeout.close(swipeoutEl);
							}
						);
					});					

					$$('.delete').on('click', function (e) {
						// had to use this bodge 
						const listEl = $$(e.target).closest('li');
						
						var id = $$(listEl).find('a').attr('id');
						if (listEl.length === 0) return;
						app.dialog.confirm('Are you sure you want to delete this consignment?',
							() => {
								if (f7.data.userIsLoggedIn) {
									f7.methods.deleteParcel(id, () => {listEl.remove();}); 
								} else {
									f7.methods.deleteOfflineParcel(id, () => {listEl.remove();}); 
								}
							}
						);						
					});					

				}
				
				if (page.name == 'parcel') {
					$$('#changedate').on('click', function () {
						var deliveryDate = $$('#delivery-date input[name=deliveryDate]:checked').val();
						var instructions = $$('#delivery-date textarea').val();
						var infoText = '';
						var infoTextError = '';
						
						var errors = [];
						
						if (!instructions) {
							instructions = $$('#delivery-date select').val();
							if ($$('#delivery-date input[name=infoText]').length) {
								infoText = $$('#delivery-date input[name=infoText]').val();
								if( !infoText) {
									infoTextError = $$('#delivery-date input[name=infoText]').attr('placeholder') + ' can not be blank';
								} else {
									instructions = instructions + ' ' + infoText;
								}
							}
						}
						
						if (deliveryDate == 'undefined') {
							errors.push('No Date Selected');
						}

						if (instructions && infoTextError) {
							errors.push(infoTextError);
						}
						
						if ($$('#delivery-date input[name=acceptterms]').length) {
							var acceptTerms = $$('#delivery-date input[name=acceptterms]:checked').val();
							if (acceptTerms != 'yes') {
								errors.push('Terms and Conditions need to be accepted');
							}
						}
						
						if (errors.length > 0) {
							var toastBottom = f7.toast.create({
							  text: errors.join('<br\>'),
							  closeTimeout: 2000,
							});
							toastBottom.open();
							return false;
						}

						var parcel = f7.methods.getParcel(f7.data.currentparcel);		
						f7.methods.changeDelivery(parcel.systemRef, parcel.postcode, deliveryDate, instructions, function() {
							if (f7.sheet.get()) {
								f7.sheet.close();
							}
							f7.view.main.router.navigate(f7.view.main.router.url, {reloadCurrent: true, ignoreCache: true});
						});
					});			

					$$('#delivery-date input[name=acceptterms]').on('change', function(e) {
						var acceptedTerms = $$('#delivery-date input[name=acceptterms]:checked').val();
						if (acceptedTerms == 'yes') {
							$$('#userAcceptedTermsDD').trigger('click');
							$$('#delivery-date textarea').focus();
						}
					});

					$$('#special-instructions select[name=leftsafeoption]').on('change', function(e) {
						var infoText = e.target.selectedOptions.item(0).dataset.infoText;
						var infoTextWidth = e.target.selectedOptions.item(0).dataset.infoTextWidth;
						if (infoText) {
							$$('#showInfoTextSI').trigger('click');
							$$('#special-instructions input[name=infoText]').attr("placeholder", infoText);
							if (infoTextWidth) {
								$$('#special-instructions input[name=infoText]').css( 'width', infoTextWidth + 'em');
								$$('#special-instructions input[name=infoText]').attr('maxlength', infoTextWidth);
							} else {
								$$('#special-instructions input[name=infoText]').attr('maxlength', '');
							}
						} else {
							$$('#hideInfoTextSI').trigger('click');
						}
					});
					
					$$('#delivery-date select[name=leftsafeoption]').on('change', function(e) {
						var infoText = e.target.selectedOptions.item(0).dataset.infoText;
						var infoTextWidth = e.target.selectedOptions.item(0).dataset.infoTextWidth;
						if (infoText) {
							$$('#showInfoTextDD').trigger('click');
							$$('#delivery-date input[name=infoText]').attr("placeholder", infoText);
							if (infoTextWidth) {
								$$('#delivery-date input[name=infoText]').css( 'width', infoTextWidth + 'em');
								$$('#delivery-date input[name=infoText]').attr('maxlength', infoTextWidth);
							} else {
								$$('#delivery-date input[name=infoText]').css( 'width', '');
								$$('#delivery-date input[name=infoText]').attr('maxlength', '');
							}
						} else {
							$$('#hideInfoTextDD').trigger('click');
						}
					});					

					$$('#special-instructions input[name=add]').on('change', function(e) {
						var addInstructions = $$('#special-instructions input:checked').val();

						if (addInstructions == 'yes') {
							$$('#showTerms').trigger('click');
							$$('#addinstructions').removeClass('disabled');
							$$('#special-instructions .special-instructions').removeClass('disabled');
							$$('#special-instructions input[name=acceptterms]').on('change', function(e) {
								var acceptedTerms = $$('#special-instructions input[name=acceptterms]:checked').val();
								if (acceptedTerms == 'yes') {
									$$('#userAcceptedTermsSI').trigger('click');
									$$('#special-instructions textarea').focus();
								}
							});
							$$('#special-instructions textarea').focus();
						} else {
							$$('#hideTerms').trigger('click');
							$$('#addinstructions').addClass('disabled');
							$$('#special-instructions .special-instructions').addClass('disabled');
						}
					});

					$$('#addinstructions').on('click', function () {
						var instructions = $$('#special-instructions textarea').val();
						var errors = [];
						var infoText = '';
						var infoTextError = '';
						
						if (!instructions) {
							instructions = $$('#special-instructions select').val();
							if ($$('#special-instructions input[name=infoText]').length) {
								infoText = $$('#special-instructions input[name=infoText]').val();
								if( !infoText) {
									infoTextError = $$('#special-instructions input[name=infoText]').attr('placeholder') + ' can not be blank';
								} else {
									instructions = instructions + ' ' + infoText;
								}
							}
						}

						
						if (!instructions) {
							errors.push('Instructions can not be blank');
						}

						if (infoTextError) {
							errors.push(infoTextError);
						}						
						
						if ($$('#special-instructions input[name=acceptterms]').length) {
							var acceptTerms = $$('#special-instructions input[name=acceptterms]:checked').val();
							if (acceptTerms != 'yes') {
								errors.push('Terms and Conditions need to be accepted');
							}
						}
						
						if (errors.length > 0) {
							var toastBottom = f7.toast.create({
							  text: errors.join('<br\>'),
							  closeTimeout: 2000,
							});
							toastBottom.open();
							return false;
						}
						var parcel = f7.methods.getParcel(f7.data.currentparcel);		
						f7.methods.addInstructions(parcel.systemRef, parcel.postcode, instructions, function(data) {
							if (f7.sheet.get()) {
								f7.sheet.close();
							}
							f7.view.main.router.navigate(f7.view.main.router.url, {reloadCurrent: true, ignoreCache: true});
						});
					});
				}

				if (page.name == 'settings') {
					f7.view.main.router.navigate(f7.view.current.router.currentRoute.url, {reloadCurrent: true, ignoreCache: true});
					// Pop up Handlers
					$$('.popup').on('popup:opened', function(e) {
						f7.form.fillFromData('#changeDisplayName', {'displayname': localStorage.getItem('displayname')});
						f7.form.fillFromData('#changeEmail', {'username': localStorage.getItem('username')});
						f7.form.fillFromData('#enableNotifications', JSON.parse(localStorage.getItem('f7form-enableNotifications')));
						var popupid = $$(e.target).attr('id');
						if (popupid == 'notification-settings') {
							if (f7.methods.notificationsEnabled() && f7.device.cordova) {
								cordova.plugins.firebase.messaging.requestPermission().then(
									function() {
									},
									function() {
										f7.dialog.alert('There is an issue on your device that is preventing you from receiving notifications.  Please enable notifications on your device.', 'Notifications Issue');
									});
							}
						}

					});
					
					// Click Change Profile 
					$$('#profile-settings .button').on('click', function (e) {
						var displayName = $$('#profile-settings input[name=displayname]').val();
						var errors = [];
						if (!displayName) {
							errors.push('Display Name can not be blank');
						} 

						f7.methods.showErrors('profile-error', errors); 
						
						if (errors.length == 0) {
							// check new password
							f7.methods.changeDisplayName(displayName, 
							// on success
							function(data) {
								if (f7.popup.get())  { f7.popup.close() };
							});
						}
					});
					
					// Click Change Email Settings
					$$('#change-email .button').on('click', function (e) {
						var email = $$('#change-email input[name=username]').val();
						var errors = [];
						if (!email) {
							errors.push('Email can not be blank');
						} else {
							if (!f7.methods.validEmail(email)) {
								errors.push('Please supply a valid email address.');
							}
						}

						f7.methods.showErrors('email-error', errors); 
						
						if (errors.length == 0) {
							// check new password
							f7.methods.changeEmailAddress(email, 
							// on success
							function(data) {
								if (f7.popup.get()) { f7.popup.close()};
							});
						}
					});
					
					// Click Change Password Settings
					$$('#change-password .button').on('click', function (e) {
						var currentPassword = $$('#change-password input[name=currentpassword]').val();
						var newPassword = $$('#change-password input[name=newpassword]').val();
						var retypePassword = $$('#change-password input[name=retypepassword]').val();
						
						var errors = [];
						
						
						
						if (!currentPassword) {
							errors.push('Current password cannot be blank');
						} else {
							// check current password
							if (f7.methods.hashPassword(currentPassword) != localStorage.getItem('password')) {
								errors.push('Current Password is incorrect');
							}
						}
						
						if (!newPassword) {
							errors.push('New password cannot be blank');
						} else {
							// check password with policy
							var passwordCheck = f7.methods.validatePassword(newPassword, retypePassword);
							if (passwordCheck.length > 0) {
								passwordCheck.forEach(function(e) {
									errors.push(e);
								});
							}
						}
						
						f7.methods.showErrors('password-error', errors); 
						
						if (errors.length == 0) {
							// check new password
							f7.methods.changePassword(f7.methods.hashPassword(newPassword), 
							// on success
							function(data) {
								if (f7.popup.get()) { f7.popup.close()};
							});
						}
						

					});
					// Click Change Password Settings
					$$('#change-password input[type=password][name=newpassword]').on('blur', function (e) {
						var newpass = $$(e.target).val();
						var checkPassword = f7.methods.validatePassword(newpass);
						f7.methods.showErrors( 'password-error', checkPassword);
					});
				
					$$('#notification-settings input[type=checkbox][name=enableNotification]').on('change', function (e) {
						var notification = $$(e.target).prop('checked');
						// only change if the current view is the settings page
						if (f7.view.current.router.currentRoute.url == '/settings/' && notification != f7.data.notificationsEnabled) {
							f7.methods.changeNotificationSettings(notification, $$(e.target));
						}						
					});



				}
				
				if (page.name == 'create-account') {
					$$('#create-account-screen input[type=password][name=password]').on('blur', function (e) {
						var password = $$(e.target).val();
						var checkPassword = f7.methods.validatePassword(password);
						f7.methods.showErrors( 'create-error', checkPassword);
					});
					
					$$('.create-button').on('click', function () {
						var username=$$('#create-account-screen input[name=username]').val();
						var password = $$('#create-account-screen input[name=password]').val();
						var retypepassword = $$('#create-account-screen input[name=retypepassword]').val();
						var displayname = $$('#create-account-screen input[name=displayname]').val();

						var errors = [];
						
						if (!displayname) {
							errors.push('Name cannot be blank');
						} 

						if (!username) {
							errors.push('Username cannot be blank');
						} else {
							if (!f7.methods.validEmail(username)) {
								errors.push('Please supply a valid email address for the Username.');
							}
						}
						if (!password) {
							errors.push('Password cannot be blank');
						} else {
							// check password with policy
							var passwordCheck = f7.methods.validatePassword(password, retypepassword);
							if (passwordCheck.length > 0) {
								passwordCheck.forEach(function(e) {
									errors.push(e);
								});
							}
						}
						
						f7.methods.showErrors('create-error', errors); 
						
						if (errors.length == 0) {
							localStorage.setItem('username', username);
							localStorage.setItem('password', f7.methods.hashPassword(password));
							
							f7.methods.createUser(displayname, function(data) {if (data.status == 'success'){f7.views.main.router.navigate('/', {ignoreCache: true});}});

						}
					});
				}
				
				if (page.name == 'forgotten-password') {
					$$('.forgotten-button').on('click', function () {
						var username=$$('#forgotten-screen input[name=username]').val();

						var errors = [];
						
						if (!username) {
							errors.push('Username can not be blank');
						} else {
							if (!f7.methods.validEmail(username)) {
								errors.push('Please supply a valid email address for the Username.');
							}
						}

						f7.methods.showErrors('forgotten-error', errors); 
						
						if (errors.length == 0) {
							localStorage.setItem('username', username);
							
							f7.methods.forgotPassword(function(data) {if (data.status == 'success'){f7.views.main.router.navigate('/', {ignoreCache: true});}});

						}
					});
				}

			}		
		},
	});
