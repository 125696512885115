
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var parcel = this.$app.methods.getParcel(this.$route.params.id);
    var email = this.$root.thirdPartyEmail;
    var leftSafe = this.$root.leftSafe;
    var leftSafeOptions = this.$root.leftSafeOptions;
    var selectedLeftSafeOption = false;
    var infoText = '';
    var infoPlaceHolder = false;
    var infoPlaceHolderWidth = '';
    var showInfoText = false;
    var firstEntry = true;
    var foundInstruction = false;
    var userIsLoggedIn = this.$app.data.userIsLoggedIn;
    if (leftSafeOptions) {
      leftSafeOptions.forEach(function (safeOption) {
        safeOption.selected = false;
        if (firstEntry) {
          firstEntry = false;
          if (safeOption.requireInfo) {
            showInfoText = true;
            infoPlaceHolder = safeOption.infoText;
            infoPlaceHolderWidth = safeOption.width;
          }
        }
        if (safeOption.requireInfo) {
          if (parcel.specialInstructions.toLowerCase().startsWith(safeOption.option.toLowerCase())) {
            selectedLeftSafeOption = safeOption.option;
            showInfoText = true;
            safeOption.selected = true;
            infoPlaceHolder = safeOption.infoText;
            infoPlaceHolderWidth = safeOption.width;
            infoText = parcel.specialInstructions.substr(safeOption.option.length).trim();
            foundInstruction = true;
          }
        } else {
          if (safeOption.option.toLowerCase() == parcel.specialInstructions.toLowerCase()) {
            selectedLeftSafeOption = safeOption.option;
            showInfoText = false;
            safeOption.selected = true;
            foundInstruction = true;
          }
        }
      });
      if (!foundInstruction && parcel.specialInstructions) {
        showInfoText = false;
        leftSafeOptions.push({
          option: parcel.specialInstructions,
          selected: true
        });
      }
    }
    return {
      thirdPartyEmail: email,
      leftSafe: leftSafe,
      leftSafeOptions: leftSafeOptions,
      infoPlaceHolder: infoPlaceHolder,
      infoPlaceHolderWidth: infoPlaceHolderWidth,
      infoText: infoText,
      showInfoTextSI: showInfoText,
      showInfoTextDD: showInfoText,
      userIsLoggedIn: userIsLoggedIn,
      showLeftSafe: false,
      acceptedTermsSI: false,
      acceptedTermsDD: false,
      parcel: parcel
    };
  },
  methods: {
    refreshPage: function (e, done) {
      var that = this.$app;
      that.views.main.router.navigate(that.views.main.router.url, { reloadCurrent: true });
      done();
    },
    showLeftSafeTerms: function (show) {
      this.$setState({ showLeftSafe: show });
    },
    userAcceptedTermsSI: function (accepted) {
      this.$setState({ acceptedTermsSI: accepted });
    },
    userAcceptedTermsDD: function (accepted) {
      this.$setState({ acceptedTermsDD: accepted });
    },
    showTextSI: function (show) {
      this.$setState({ showInfoTextSI: show });
    },
    showTextDD: function (show) {
      this.$setState({ showInfoTextDD: show });
    }
  },
  on: {
    pageInit: function (e, page) {
      var parcel = this.$app.methods.getParcel(this.$route.params.id);
      var map = L.map('map').setView([
        parcel.delLat,
        parcel.delLong
      ], 13);
      var delLabel = 'Driver';
      var deliveryIcon = L.icon({
        iconUrl: this.$app.data.leafletIcons.delivery,
        iconSize: [
          65,
          72
        ],
        iconAnchor: [
          20,
          68
        ],
        popupAnchor: [
          -3,
          -50
        ]
      });
      var driverIcon = L.icon({
        iconUrl: this.$app.data.leafletIcons.driver,
        iconSize: [
          65,
          72
        ],
        iconAnchor: [
          20,
          68
        ],
        popupAnchor: [
          -3,
          -50
        ]
      });
      var osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
      var osmAttrib = 'Map data \xA9 <a href="https://openstreetmap.org" class="external">OpenStreetMap</a> contributors';
      var osm = new L.TileLayer(osmUrl, {
        minZoom: 6,
        maxZoom: 16,
        attribution: osmAttrib
      });
      var destMarkerLabel = parcel.postcode;
      map.addLayer(osm);
      if (parcel.consigneeName) {
        destMarkerLabel = parcel.consigneeName;
      } else {
        if (parcel.company) {
          destMarkerLabel = parcel.company;
        }
      }
      if (parcel.delLabel) {
        delLabel = parcel.delLabel;
      }
      var driverLat = parcel.delLat;
      var driverLong = parcel.delLong;
      if (parcel.drvLong) {
        driverLat = parcel.drvLat;
        driverLong = parcel.drvLong;
      }
      var destmarker = L.marker([
        parcel.delLat,
        parcel.delLong
      ], { icon: deliveryIcon }).bindPopup(destMarkerLabel, {
        closeOnClick: false,
        autoClose: false
      }).addTo(map);
      var delmarker = L.marker([
        driverLat,
        driverLong
      ], { icon: driverIcon }).bindPopup(delLabel, {
        closeOnClick: false,
        autoClose: false
      });
      if (parcel.drvLong) {
        delmarker.addTo(map);
      }
      this.$app.data.map = map;
      this.$app.data.mapdriver = delmarker;
      this.$app.data.mapdestination = destmarker;
      this.$app.data.currentparcel = this.$route.params.id;
      this.$app.methods.refreshDriver();
    },
    pageAfterIn: function () {
    }
  },
  id: '13c07caa48',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n\t<div class="page" data-name="parcel">\n\t\t<div class="navbar">\n\t\t\t<div class="navbar-inner sliding">\n\t\t\t\t<div class="title">';
      r += c(ctx_1.parcel.account, ctx_1);
      r += '</div>\n\t\t\t\t<div class="right">\n\t               <a href="#" class="link back">Close</a>\n    \t\t\t</div>\t\n\t\t\t</div>\t\t\n\t\t</div>\n\t\t<!-- Scrollable page content-->\n\t\t<!-- Extended FAB Center Bottom (Red) -->\n\t\t<div class="fab fab-extended fab-left-bottom color-red">\n\t\t\t';
      r += Template7Helpers.js_if.call(ctx_1, 'typeof(cordova) != \'undefined\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t\t\t<a data-tap-disabled="true" href="#" onclick="cordova.plugins.email.open({to: \'';
          r += c(ctx_2.thirdPartyEmail, ctx_2);
          r += '\', subject: \'';
          r += c(ctx_2.parcel.consignmentNo, ctx_2);
          r += ' - ';
          r += c(ctx_2.parcel.postcode, ctx_2);
          r += ' Update\'}); return false;" class="item-link item-content external" target="_system">\n\t\t\t';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t\t\t<a data-tap-disabled="true" href="mailto:';
          r += c(ctx_2.thirdPartyEmail, ctx_2);
          r += '?subject=';
          r += c(ctx_2.parcel.consignmentNo, ctx_2);
          r += ' - ';
          r += c(ctx_2.parcel.postcode, ctx_2);
          r += ' Update" class="item-link item-content external" target="_system">\n\t\t\t';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\t\t\t\t<i class="icon f7-icons if-ios">email_fill</i>\n\t\t\t\t<i class="icon material-icons if-md">email</i>\n\t\t\t</a>\n\t\t</div>\t\t\n\t\t<div class="fab fab-extended fab-center-bottom color-red">\n\t\t\t<a class="sheet-open" href="#" data-sheet=".instructions">\n\t\t\t\t<i class="icon f7-icons">info_fill</i>\n\t\t\t</a>\n\t\t</div>\t\t\n\t\t<div class="fab fab-extended fab-right-bottom color-red">\n\t\t\t<a class="sheet-open" href="#" data-sheet=".deldate">\n\t\t\t\t<i class="icon f7-icons">calendar_today_fill</i>\n\t\t\t</a>\n\t\t</div>\t\t\n\t\t<div class="page-content"> \n\t\t\t<div id="deliveryInfo" style="display: none">\n\t\t\t\t<div class="alert">\n\t\t\t\t\t<i class="fa"></i>\n\t\t\t\t\t<span class="message"></span>\n\t\t\t\t\t<p id="progressBar" style="display: none">\n\t\t\t\t\t\t<progress id="conProgress" value="0" max="100" style="width:80%; margin-right:0.5em"></progress>\n\t\t\t\t\t\t<i class="fas"></i>\n\t\t\t\t\t</p>\n\t\t\t\t\t<span class="lastupdated"></span>\t\t\t\t\t\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div id="map" class="full-map"></div>\n\t\t</div>\n\n\t\t<!-- Default bottom sheet -->\n\t\t<!-- Popups -->\n\t\t<div class="sheet-modal instructions" style="height: auto;">\n\t\t\t<div class="toolbar">\n\t\t\t\t<div class="toolbar-inner">\n\t\t\t\t\t<div class="left"></div>\n\t\t\t\t\t<div class="right"><a class="link sheet-close" href="#">Done</a></div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="sheet-modal-inner">\n\t\t\t\t<div class="page-content block">\n\t\t\t\t\t<h4><i class="fa fa-bullhorn"></i> Special Instructions</h4>\n\t\t\t\t\t';
      r += Template7Helpers.if.call(ctx_1, ctx_1.userIsLoggedIn, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t\t\t\t\t<form class="list" id="special-instructions">\n\t\t\t\t\t\t';
          r += Template7Helpers.if.call(ctx_2, ctx_2.parcel.instructionErrors, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\t\t\t\t\t\t<div class="alert alert-danger">\n\t\t\t\t\t\t\t<i class="fa fa-exclamation-triangle"></i> Unable to add special instructions:\n\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t';
              r += Template7Helpers.each.call(ctx_3, ctx_3.parcel.instructionErrors, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n\t\t\t\t\t\t\t\t<li>';
                  r += c(ctx_4, ctx_4);
                  r += '</li>\n\t\t\t\t\t\t\t\t';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t';
              r += Template7Helpers.if.call(ctx_3, ctx_3.leftSafeOptions, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.requireInfo, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += Template7Helpers.if.call(ctx_4, ctx_4.width, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += Template7Helpers.if.call(ctx_4, ctx_4.selected, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += Template7Helpers.if.call(ctx_4, ctx_4.showInfoTextSI, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.infoPlaceHolderWidth, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n\t\t\t\t\t\t<h5>Would you like to add any special instructions?</h5>\n\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t<label class="item-radio item-content">\n\t\t\t\t\t\t\t\t<input type="radio" name="add" value="yes" />\n\t\t\t\t\t\t\t\t<i class="icon icon-radio"></i>\n\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t<div class="item-title">Yes</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t<label class="item-radio item-content">\n\t\t\t\t\t\t\t\t<input type="radio" name="add" value="no" checked />\n\t\t\t\t\t\t\t\t<i class="icon icon-radio"></i>\n\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t<div class="item-title">No</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t<div class="item-content item-input special-instructions disabled">\n\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t<div class="item-title item-label">Instructions</div>\n\t\t\t\t\t\t\t\t\t\t';
              r += Template7Helpers.if.call(ctx_3, ctx_3.leftSafeOptions, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n\t\t\t\t\t\t\t\t\t\t<div class="input input-dropdown">\n\t\t\t\t\t\t\t\t\t\t\t<select name="leftsafeoption">\n\t\t\t\t\t\t\t\t\t\t\t\t';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.leftSafeOptions, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n\t\t\t\t\t\t\t\t\t\t\t\t<option value="';
                      r += c(ctx_5.option, ctx_5);
                      r += '" data-info-text="';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.requireInfo, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += c(ctx_6.infoText, ctx_6);
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '"  data-info-text-width="';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.width, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += c(ctx_6.width, ctx_6);
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '" ';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.selected, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += 'selected';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '>';
                      r += c(ctx_5.option, ctx_5);
                      r += '</option>\n\t\t\t\t\t\t\t\t\t\t\t\t';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n\t\t\t\t\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.showInfoTextSI, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n\t\t\t\t\t\t\t\t\t\t<div class="input input-wrap">\n\t\t\t\t\t\t\t\t\t\t\t<input name="infoText" class="input" ';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.infoPlaceHolderWidth, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += 'maxlength="';
                          r += c(ctx_6.infoPlaceHolderWidth, ctx_6);
                          r += '" style="width: ';
                          r += c(ctx_6.infoPlaceHolderWidth, ctx_6);
                          r += 'em;"';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += ' placeholder="';
                      r += c(ctx_5.infoPlaceHolder, ctx_5);
                      r += '" value="';
                      r += c(ctx_5.infoText, ctx_5);
                      r += '" />\n\t\t\t\t\t\t\t\t\t\t</div>\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n\t\t\t\t\t\t\t\t\t\t';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n\t\t\t\t\t\t\t\t\t\t<div class="item-input-wrap">\n\t\t\t\t\t\t\t\t\t\t\t<textarea class="resizable" placeholder="Your instruction">';
                  r += c(ctx_4.parcel.specialInstructions, ctx_4);
                  r += '</textarea>\n\t\t\t\t\t\t\t\t\t\t\t<span class="input-clear-button"></span>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\t\n\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\t\t\t\t\t\t';
          r += Template7Helpers.if.call(ctx_2, ctx_2.showLeftSafe, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\t\t\t\t\t\t';
              r += Template7Helpers.if.call(ctx_3, ctx_3.leftSafe, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\t\t\t\t\t\t\n\t\t\t\t\t\t';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.acceptedTermsSI, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n\t\t\t\t\t\t<div class="alert alert-info">\n\t\t\t\t\t\t\t<i class="fa fa-check-circle"></i>Terms and Conditions Accepted\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n\t\t\t\t\t\t<div class="alert alert-danger">\n\t\t\t\t\t\t\t<i class="fa fa-exclamation-triangle"></i><strong>Please Accept these Terms and Conditions:</strong>\n\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t<li>';
                      r += c(ctx_5.leftSafe, ctx_5);
                      r += '</li>\n\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t<label class="item-checkbox item-content">\n\t\t\t\t\t\t\t\t\t\t<input type="checkbox" name="acceptterms" value="yes"/>\n\t\t\t\t\t\t\t\t\t\t<i class="icon icon-checkbox" style="margin-right: 0px"></i>\n\t\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t\t<strong>I accept these terms and conditions</strong>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</label>\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n\t\t\t\t\t\t';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\t\t\t\t\t\t';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\t\t\t\t\t\t<a class="button button-active disabled" id="addinstructions">Add Instructions</a>\n\t\t\t\t\t\t<a id="showTerms" class="button button-fill" @click="showLeftSafeTerms(true)" style="display: none">Show Terms</a>\t\n\t\t\t\t\t\t<a id="hideTerms" class="button button-fill" @click="showLeftSafeTerms(false)" style="display: none">Hide Terms</a>\t\n\t\t\t\t\t\t<a id="showInfoTextSI" class="button button-fill" @click="showTextSI(true)" style="display: none">Show Info Text</a>\t\n\t\t\t\t\t\t<a id="hideInfoTextSI" class="button button-fill" @click="showTextSI(false)" style="display: none">Hide Info Text</a>\t\n\t\t\t\t\t\t<a id="userAcceptedTermsSI" class="button button-fill" @click="userAcceptedTermsSI(true)" style="display: none">User Accepted Special Instructions Terms</a>\n\t\t\t\t\t</form>\n\t\t\t\t\t';
          r += Template7Helpers.if.call(ctx_2, ctx_2.parcel.instructionErrors, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t\t\t\t\t';
          r += Template7Helpers.if.call(ctx_2, ctx_2.parcel.instructionErrors, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\t\t\t\t\t<form class="list" id="special-instructions">\n\t\t\t\t\t<div class="alert alert-danger">\n\t\t\t\t\t\t<i class="fa fa-exclamation-triangle"></i> Unable to add special instructions:\n\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t';
              r += Template7Helpers.each.call(ctx_3, ctx_3.parcel.instructionErrors, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n\t\t\t\t\t\t\t<li>';
                  r += c(ctx_4, ctx_4);
                  r += '</li>\n\t\t\t\t\t\t\t';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\t\t\t\t\t\t</ul>\n\t\t\t\t\t</div>\n\t\t\t\t\t</form>\n\t\t\t\t\t';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n\t\t\t\t\t<p>Only registered users can add Special Instructions</p>\n\t\t\t\t\t<p><a href="/login/">Click here if you have an account and want to log in</a></p>\n\t\t\t\t\t<p><a href="/create/">Click here to register with us</a></p>\n\t\t\t\t\t<p><a href="/forgotten/">Click here if you have forgotten your password</a></p>\n\t\t\t\t\t';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\t\t\t\t\t';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\t\t\n\t\t<div class="sheet-modal deldate" style="height: auto;">\n\t\t\t<div class="toolbar">\n\t\t\t\t<div class="toolbar-inner">\n\t\t\t\t\t<div class="left"></div>\n\t\t\t\t\t<div class="right"><a class="link sheet-close" href="#">Close</a></div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="sheet-modal-inner">\n\t\t\t\t<div class="page-content block">\n\t\t\t\t\t<h4><i class="fa fa-calendar"></i> Change Delivery Date</h4>\n\t\t\t\t\t';
      r += Template7Helpers.if.call(ctx_1, ctx_1.userIsLoggedIn, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t\t\t\t\t<form class="list" id="delivery-date">\n\t\t\t\t\t\t';
          r += Template7Helpers.if.call(ctx_2, ctx_2.parcel.availableDays, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t';
              r += Template7Helpers.each.call(ctx_3, ctx_3.parcel.availableDays, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t<label class="item-radio item-content">\n\t\t\t\t\t\t\t\t\t<!-- Radio input -->\n\t\t\t\t\t\t\t\t\t<input type="radio" name="deliveryDate" value="';
                  r += c(ctx_4.value, ctx_4);
                  r += '" ';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.selected, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' checked ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '/>\n\t\t\t\t\t\t\t\t\t<!-- Radio icon -->\n\t\t\t\t\t\t\t\t\t<i class="icon icon-radio"></i>\n\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t<!-- Radio Title -->\n\t\t\t\t\t\t\t\t\t\t<div class="item-title">';
                  r += c(ctx_4.value, ctx_4);
                  r += '</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t</li>\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\t\t\t\t\t\t\t<li>\n\n\t\t\t\t\t\t\t\t';
              r += Template7Helpers.if.call(ctx_3, ctx_3.parcel.instructionErrors, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n\t\t\t\t\t\t\t\t<div class="alert alert-danger">\n\t\t\t\t\t\t\t\t\t<i class="fa fa-exclamation-triangle"></i> Unable to add special instructions:\n\t\t\t\t\t\t\t\t\t\t';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.parcel.instructionErrors, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n\t\t\t\t\t\t\t\t\t\t<p>';
                      r += c(ctx_5, ctx_5);
                      r += '</p>\n\t\t\t\t\t\t\t\t\t\t';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.leftSafeOptions, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.requireInfo, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += Template7Helpers.if.call(ctx_5, ctx_5.width, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += Template7Helpers.if.call(ctx_5, ctx_5.selected, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += Template7Helpers.if.call(ctx_5, ctx_5.showInfoTextDD, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.infoPlaceHolderWidth, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n\n\t\t\t\t\t\t\t\t<div class="item-content item-input special-instructions">\n\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t<div class="item-title item-label">Instructions</div>\n\t\t\t\t\t\t\t\t\t\t';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.leftSafeOptions, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n\t\t\t\t\t\t\t\t\t\t<div class="input input-dropdown">\n\t\t\t\t\t\t\t\t\t\t\t<select name="leftsafeoption">\n\t\t\t\t\t\t\t\t\t\t\t\t';
                      r += Template7Helpers.each.call(ctx_5, ctx_5.leftSafeOptions, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n\t\t\t\t\t\t\t\t\t\t\t\t<option value="';
                          r += c(ctx_6.option, ctx_6);
                          r += '" data-info-text="';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.requireInfo, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += c(ctx_7.infoText, ctx_7);
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '"  data-info-text-width="';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.width, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += c(ctx_7.width, ctx_7);
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '" ';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.selected, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += 'selected';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '>';
                          r += c(ctx_6.option, ctx_6);
                          r += '</option>\n\t\t\t\t\t\t\t\t\t\t\t\t';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n\t\t\t\t\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.showInfoTextDD, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n\t\t\t\t\t\t\t\t\t\t<div class="input input-wrap">\n\t\t\t\t\t\t\t\t\t\t\t<input name="infoText" class="input" ';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.infoPlaceHolderWidth, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += 'maxlength="';
                              r += c(ctx_7.infoPlaceHolderWidth, ctx_7);
                              r += '" style="width: ';
                              r += c(ctx_7.infoPlaceHolderWidth, ctx_7);
                              r += 'em;"';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += ' placeholder="';
                          r += c(ctx_6.infoPlaceHolder, ctx_6);
                          r += '" value="';
                          r += c(ctx_6.infoText, ctx_6);
                          r += '" />\n\t\t\t\t\t\t\t\t\t\t</div>\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n\t\t\t\t\t\t\t\t\t\t';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n\t\t\t\t\t\t\t\t\t\t<div class="item-input-wrap">\n\t\t\t\t\t\t\t\t\t\t\t<textarea class="resizable" placeholder="Your instruction">';
                      r += c(ctx_5.parcel.specialInstructions, ctx_5);
                      r += '</textarea>\n\t\t\t\t\t\t\t\t\t\t\t<span class="input-clear-button"></span>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t';
              r += Template7Helpers.unless.call(ctx_3, ctx_3.parcel.instructionErrors, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n\t\t\t\t\t\t';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.leftSafe, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n\t\t\t\t\t\t';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.acceptedTermsDD, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n\t\t\t\t\t\t<div class="alert alert-info">\n\t\t\t\t\t\t\t<i class="fa fa-check-circle"></i>Terms and Conditions Accepted\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '\t\t\t\t\t\t\n\t\t\t\t\t\t<div class="alert alert-danger">\n\t\t\t\t\t\t\t<i class="fa fa-exclamation-triangle"></i><strong>Please Accept these Terms and Conditions:</strong>\n\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t<li>';
                          r += c(ctx_6.leftSafe, ctx_6);
                          r += '</li>\n\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t<label class="item-checkbox item-content">\n\t\t\t\t\t\t\t\t\t\t<input type="checkbox" name="acceptterms" value="yes"/>\n\t\t\t\t\t\t\t\t\t\t<i class="icon icon-checkbox" style="margin-right: 0px"></i>\n\t\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t\t<strong>I accept these terms and conditions</strong>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</label>\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t';
                          return r;
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n\t\t\t\t\t\t';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n\t\t\t\t\t\t';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\t\t\t\t\t\t<a class="button button-active" id="changedate">Change Date</a>\n\t\t\t\t\t\t<a id="showInfoTextDD" class="button button-fill" @click="showTextDD(true)" style="display: none">Show Info Text</a>\t\n\t\t\t\t\t\t<a id="hideInfoTextDD" class="button button-fill" @click="showTextDD(false)" style="display: none">Hide Info Text</a>\t\n\t\t\t\t\t\t<a id="userAcceptedTermsDD" class="button button-fill" @click="userAcceptedTermsDD(true)" style="display: none">User Accepted Due Date Special Instructions Terms</a>\n\t\t\t\t\t\t';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n\t\t\t\t\t\t<div class="alert alert-danger">\n\t\t\t\t\t\t\t<i class="fa fa-exclamation-triangle"></i> Unable to change delivery date:\n\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t';
              r += Template7Helpers.each.call(ctx_3, ctx_3.parcel.bookingErrors, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n\t\t\t\t\t\t\t\t<li>';
                  r += c(ctx_4, ctx_4);
                  r += '</li>\n\t\t\t\t\t\t\t\t';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\t\t\t\t\t</form>\n\t\t\t\t\t';
          r += Template7Helpers.if.call(ctx_2, ctx_2.parcel.bookingErrors, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n\t\t\t\t\t';
          r += Template7Helpers.if.call(ctx_2, ctx_2.parcel.bookingErrors, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\t\t\t\t\t<form class="list" id="delivery-date">\n\t\t\t\t\t\t<div class="alert alert-danger">\n\t\t\t\t\t\t\t<i class="fa fa-exclamation-triangle"></i> Unable to change delivery date:\n\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t';
              r += Template7Helpers.each.call(ctx_3, ctx_3.parcel.bookingErrors, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n\t\t\t\t\t\t\t\t<li>';
                  r += c(ctx_4, ctx_4);
                  r += '</li>\n\t\t\t\t\t\t\t\t';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</form>\n\t\t\t\t\t';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n\t\t\t\t\t<p>Only registered users can change the delivery date or add Special Instructions</p>\n\t\t\t\t\t<p><a href="/login/">Click here if you have an account and want to log in</a></p>\n\t\t\t\t\t<p><a href="/create/">Click here to register with us</a></p>\n\t\t\t\t\t<p><a href="/forgotten/">Click here if you have forgotten your password</a></p>\n\t\t\t\t\t';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\t\t\t\t\t';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\t\t\n\t\t\n\t\t\n\t</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    